// extracted by mini-css-extract-plugin
export var collectionListing = "collection-module--collection-listing--a6205";
export var collectionListingContainer = "collection-module--collection-listing-container--d7124";
export var collectionListingHeaderSection = "collection-module--collection-listing-header-section--83067";
export var collectionListingHeaderSectionDescription = "collection-module--collection-listing-header-section-description--b2bd4";
export var collectionListingHeaderSectionHeading = "collection-module--collection-listing-header-section-heading--8538b";
export var collectionPage = "collection-module--collection-page--7d004";
export var collectionProjectCount = "collection-module--collection-project-count--9735d";
export var collectionsPage = "collection-module--collections-page--210e9";
export var collectionsPageCollectionLink = "collection-module--collections-page-collection-link--4fa9e";
export var featuredVideoContainer = "collection-module--featured-video-container--f160b";
export var pageHeading = "collection-module--page-heading--c9852";
export var primaryBodyCopy = "collection-module--primary-body-copy--898a1";
export var primaryBodyCopyDescription = "collection-module--primary-body-copy-description--7cf0d";
export var primaryBodyCopyHeader = "collection-module--primary-body-copy-header--f74ba";
export var project = "collection-module--project--09b60";